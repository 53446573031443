<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Müşteri Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Müşteri Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Müşteri Listesi",
          href: "/customer",
        },
        {
          text: "Müşteri Ekle",
          active: true,
        },
      ],
      newCustomer: {
        customer_name: "",
        customer_phone: "",
        customer_email: "",
        customer_passport_number: "",
        customer_nationality: "",
        isActive: 1,
      },
    };
  },
  methods: {
    submitNewCustomer() {
      const api_url = process.env.VUE_APP_BASEURL + "/customer";
      axios
        .post(api_url, this.newCustomer, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/customer");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submitNewCustomer">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_name">Müşteri Adı:</label>
                    <input
                      v-model="newCustomer.customer_name"
                      type="text"
                      class="form-control"
                      id="customer_name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_phone">Müşteri Telefon Numarası:</label>
                    <input
                      v-model="newCustomer.customer_phone"
                      type="text"
                      class="form-control"
                      id="customer_phone"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_email">Müşteri Mail:</label>
                    <input
                      v-model="newCustomer.customer_email"
                      type="text"
                      class="form-control"
                      id="customer_email"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_passport_number">Müşteri Pasaport Numarası:</label>
                    <input
                      v-model="newCustomer.customer_passport_number"
                      type="text"
                      class="form-control"
                      id="customer_passport_number"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_nationality">Müşteri Uyruğu:</label>
                    <input
                      v-model="newCustomer.customer_nationality"
                      type="text"
                      class="form-control"
                      id="customer_nationality"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Ekle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
